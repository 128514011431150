package com.vermek.login

import com.vermek.AuthenticationContext
import com.vermek.ThemeContext
import com.vermek.modules.authentication.AuthenticationModule
import com.vermek.modules.authentication.AuthenticationModuleDeps
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Button
import mui.material.ButtonVariant
import mui.system.Box
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.router.useNavigate
import react.useRequiredContext
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import web.html.InputType

val LoginComponent = FC<Props> {
    val theme by useRequiredContext(ThemeContext)
    val authContext by useRequiredContext(AuthenticationContext)
    val navigate = useNavigate()
    val authenticationModule = AuthenticationModule(AuthenticationModuleDeps())
    var email by useState("")
    var password by useState("")
    var isError by useState(false)
    var errorMessage by useState("")

    // If the user is already logged in we want to do a redirect to the home page from here
    // TODO - This redirect causes some console errors. Not sure why yet
    if (authContext.isAuthenticated()) {
        navigate("/")
    }

    /**
     * Handles login button clicked
     */
    fun handleLoginClicked() {
        authenticationModule.handleSignInUserWithEmailAndPassword(email, password) { error ->
            if (error != null) {
                isError = true
                errorMessage = error.message ?: "Oops, please try again."
            }
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            width = 100.pct
            height = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                width = 300.px
                backgroundColor = theme.palette.secondary.main
                padding = 20.px
                borderRadius = 10.px
            }

            Typography {
                sx {
                    paddingBottom = 20.px
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.h5

                +"Login"
            }

            TextField {
                label = ReactNode("Email")
                fullWidth = true
                onChange = { event -> email = event.target.asDynamic().value as String }
            }

            TextField {
                sx {
                    marginTop = 10.px
                }
                label = ReactNode("Password")
                type = InputType.password
                fullWidth = true
                onChange = { event -> password = event.target.asDynamic().value as String }
            }

            Button {
                sx {
                    marginTop = 10.px
                }
                fullWidth = true
                variant = ButtonVariant.contained
                onClick = { handleLoginClicked() }
                +"Login"
            }

            if (isError) {
                Alert {
                    sx {
                        marginTop = 10.px
                        width = 100.pct
                    }
                    severity = AlertColor.error

                    +errorMessage
                }
            }
        }
    }
}