package com.vermek.core.authentication.firebase

import com.vermek.core.authentication.models.AuthenticationToken
import com.vermek.core.authentication.models.AuthenticationUser
import kotlinx.coroutines.await

actual val Firebase.auth
    get() = rethrow {
        authentication; FirebaseAuth(authentication.getAuth())
    }

actual fun Firebase.auth(app: FirebaseApp) =
    rethrow { firebase.auth; FirebaseAuth(firebase.auth(app.js)) }

actual open class AuthCredential(val js: firebase.auth.AuthCredential) {
    actual val providerId: String
        get() = js.providerId
}

actual class PhoneAuthCredential(js: firebase.auth.AuthCredential) : AuthCredential(js)

actual class FirebaseAuth internal constructor(val js: authentication.Auth) {

    actual val currentUser: FirebaseUser?
        get() = rethrow { js.currentUser?.let { FirebaseUser(it) } }

    actual fun onAuthStateChanged(completion: (FirebaseUser?) -> Unit): () -> Unit = rethrow {
        return authentication.onAuthStateChanged(authentication.getAuth(), {
            completion(it?.let { user -> FirebaseUser(user) })
        }, {}, {})
    }


    actual suspend fun signInWithEmailAndPassword(email: String, password: String) =
        rethrow { AuthResult(authentication.signInWithEmailAndPassword(authentication.getAuth(), email, password).await()) }

//    actual suspend fun signInWithCredential(authCredential: AuthCredential) =
//        rethrow { /*AuthResult(js.signInWithCredential(authCredential.js).await())*/ }

    actual suspend fun signOut() = rethrow { authentication.signOut(authentication.getAuth()).await() }
}

actual class PhoneAuthProvider(val js: firebase.auth.PhoneAuthProvider) {

    actual constructor(auth: FirebaseAuth) : this(firebase.auth.PhoneAuthProvider(auth.js))

    actual fun credential(verificationId: String, smsCode: String): PhoneAuthCredential = PhoneAuthCredential(firebase.auth.PhoneAuthProvider.credential(verificationId, smsCode))
    actual suspend fun verifyPhoneNumber(phoneNumber: String, verificationProvider: PhoneVerificationProvider): AuthCredential = rethrow {
        val verificationId = js.verifyPhoneNumber(phoneNumber, verificationProvider.verifier).await()
        val verificationCode = verificationProvider.getVerificationCode(verificationId)
        credential(verificationId, verificationCode)
    }
}

actual interface PhoneVerificationProvider {
    val verifier: firebase.auth.ApplicationVerifier
    suspend fun getVerificationCode(verificationId: String): String
}

actual class AuthResult internal constructor(val js: authentication.UserCredential) {
    actual val user: FirebaseUser?
        get() = rethrow { js.user?.let { FirebaseUser(it) } }
}

actual class AuthTokenResult(val js: firebase.auth.IdTokenResult) {
    actual val claims: Map<String, Any>
        get() = (js("Object").keys(js.claims) as Array<String>).mapNotNull {
                key -> js.claims[key]?.let { key to it }
        }.toMap()
    actual val signInProvider: String?
        get() = js.signInProvider
    actual val token: String?
        get() = js.token
}

actual open class FirebaseAuthException(code: String?, cause: Throwable): FirebaseException(code, cause)
actual open class FirebaseAuthActionCodeException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthEmailException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthInvalidCredentialsException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthInvalidUserException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthMultiFactorException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthRecentLoginRequiredException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthUserCollisionException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)
actual open class FirebaseAuthWebException(code: String?, cause: Throwable): FirebaseAuthException(code, cause)


internal inline fun <T, R> T.rethrow(function: T.() -> R): R = com.vermek.core.authentication.firebase.rethrow { function() }

private inline fun <R> rethrow(function: () -> R): R {
    try {
        return function()
    } catch (e: Exception) {
        throw e
    } catch(e: dynamic) {
        throw errorToException(e)
    }
}

private fun errorToException(cause: dynamic) = when(val code = cause.code?.toString()?.lowercase()) {
    "auth/invalid-user-token" -> FirebaseAuthInvalidUserException(code, cause)
    "auth/requires-recent-login" -> FirebaseAuthRecentLoginRequiredException(code, cause)
    "auth/user-disabled" -> FirebaseAuthInvalidUserException(code, cause)
    "auth/user-token-expired" -> FirebaseAuthInvalidUserException(code, cause)
    "auth/web-storage-unsupported" -> FirebaseAuthWebException(code, cause)
    "auth/network-request-failed" -> FirebaseNetworkException(code, cause)
    "auth/invalid-credential",
    "auth/invalid-verification-code",
    "auth/missing-verification-code",
    "auth/invalid-verification-id",
    "auth/missing-verification-id" -> FirebaseAuthInvalidCredentialsException(code, cause)
    "auth/maximum-second-factor-count-exceeded",
    "auth/second-factor-already-in-use" -> FirebaseAuthMultiFactorException(code, cause)
    "auth/credential-already-in-use" -> FirebaseAuthUserCollisionException(code, cause)
    "auth/email-already-in-use" -> FirebaseAuthUserCollisionException(code, cause)
    "auth/invalid-email" -> FirebaseAuthEmailException(code, cause)

//                "auth/app-deleted" ->
//                "auth/app-not-authorized" ->
//                "auth/argument-error" ->
//                "auth/invalid-api-key" ->
//                "auth/operation-not-allowed" ->
//                "auth/too-many-arguments" ->
//                "auth/unauthorized-domain" ->
    else -> {
        println("Unknown error code in ${JSON.stringify(cause)}")
        FirebaseAuthException(code, cause)
    }
}

actual class FirebaseUser internal constructor(val js: firebase.user.User): AuthenticationUser {
    override val uid: String
        get() = rethrow { js.uid }
    actual val displayName: String?
        get() = rethrow { js.displayName }
    actual val email: String?
        get() = rethrow { js.email }
    actual val phoneNumber: String?
        get() = rethrow { js.phoneNumber }
    actual val photoURL: String?
        get() = rethrow { js.photoURL }
    actual val isAnonymous: Boolean
        get() = rethrow { js.isAnonymous }
    actual val isEmailVerified: Boolean
        get() = rethrow { js.emailVerified }
    actual val providerId: String
        get() = rethrow { js.providerId }
    actual suspend fun delete() = rethrow { js.delete().await() }
    actual suspend fun reload() = rethrow { js.reload().await() }
    actual suspend fun getIdTokenResult(forceRefresh: Boolean): AuthTokenResult = rethrow { AuthTokenResult(authentication.getIdTokenResult(js, forceRefresh).await()) }
    override suspend fun getToken(): AuthenticationToken? = getIdTokenResult(false).token?.let { AuthenticationToken(it) }
}

actual class UserInfo(val js: firebase.user.UserInfo) {
    actual val email: String?
        get() = rethrow { js.email }
    actual val phoneNumber: String?
        get() = rethrow { js.phoneNumber }
    actual val uid: String
        get() = rethrow { js.uid }
}
