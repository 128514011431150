package com.vermek.header

import com.vermek.AuthenticationContext
import com.vermek.header.components.HeaderAuthenticated
import com.vermek.header.components.HeaderSkeleton
import com.vermek.header.components.HeaderUnauthenticated
import mui.material.AppBar
import mui.material.AppBarColor
import mui.material.AppBarPosition
import mui.material.Toolbar
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.useRequiredContext
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.ident
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

object HeaderConstants {
    val height = 64.px
    val area = ident("header")
}

val Header = FC<Props> {
    val authContext by useRequiredContext(AuthenticationContext)

    AppBar {
        position = AppBarPosition.fixed
        color = AppBarColor.secondary
        elevation = 0
        sx {
            gridArea = HeaderConstants.area
            zIndex = integer(1_500)
            justifyContent = JustifyContent.center
        }

        Toolbar {
            if (authContext.isAuthenticated()) {
                HeaderAuthenticated()
            } else if (authContext.isUnauthenticated()) {
                HeaderUnauthenticated()
            } else {
                HeaderSkeleton()
            }
        }
    }
}