//
// AUTO-GENERATED FILE. DO NOT MODIFY.
//
// This class was automatically generated by Apollo GraphQL version '3.5.0'.
//
package com.vermek.adapter

import com.apollographql.apollo3.api.Adapter
import com.apollographql.apollo3.api.CustomScalarAdapters
import com.apollographql.apollo3.api.StringAdapter
import com.apollographql.apollo3.api.json.JsonReader
import com.apollographql.apollo3.api.json.JsonWriter
import com.apollographql.apollo3.api.list
import com.vermek.SendSMSMessageMutation
import kotlin.IllegalStateException
import kotlin.Unit

public object SendSMSMessageMutation_VariablesAdapter : Adapter<SendSMSMessageMutation> {
  public override fun fromJson(reader: JsonReader, customScalarAdapters: CustomScalarAdapters):
      SendSMSMessageMutation = throw IllegalStateException("Input type used in output position")

  public override fun toJson(
    writer: JsonWriter,
    customScalarAdapters: CustomScalarAdapters,
    `value`: SendSMSMessageMutation,
  ): Unit {
    writer.name("phoneNumbers")
    StringAdapter.list().toJson(writer, customScalarAdapters, value.phoneNumbers)
    writer.name("message")
    StringAdapter.toJson(writer, customScalarAdapters, value.message)
  }
}
