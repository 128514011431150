package com.vermek

import com.vermek.modules.application.ApplicationModule
import react.FC
import react.PropsWithChildren
import react.createContext

data class AppDependencies(
    val applicationModule: ApplicationModule
)

val AppDependencyContext = createContext<AppDependencies>()
val AppDependencyProvider = FC<PropsWithChildren> { props ->
    val applicationModule = ApplicationModule()

    val dependencies = AppDependencies(
        applicationModule
    )

    AppDependencyContext(dependencies) {
        +props.children
    }
}