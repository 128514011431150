package com.vermek

import com.vermek.core.style.VMColors
import js.core.jso
import mui.material.styles.createTheme
import web.cssom.Color

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                primary = jso { main = VMColors.vermekPurple }
                secondary = jso { main = VMColors.white }

                text = jso {
                    primary = Color(VMColors.black)
                }
            }
        }
    )
}