package com.vermek.root

import com.vermek.header.Header
import com.vermek.header.HeaderConstants
import com.vermek.ThemeContext
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.Outlet
import react.useRequiredContext
import web.cssom.Auto
import web.cssom.Display
import web.cssom.GridTemplateAreas
import web.cssom.array
import web.cssom.ident
import web.cssom.pct
import web.cssom.px
import web.cssom.vh

object RootConstants {
    val area = ident("content")
}

val RootComponent = FC<Props> {
    val theme by useRequiredContext(ThemeContext)

    Box {
        sx {
            width = 100.pct
            height = 100.vh
            backgroundColor = theme.palette.primary.main
        }

        Box {
            sx {
                display = Display.grid
                gridTemplateRows = array(
                    HeaderConstants.height,
                    Auto.auto
                )
                gridTemplateAreas = GridTemplateAreas(
                    arrayOf(HeaderConstants.area, HeaderConstants.area),
                    arrayOf(RootConstants.area, RootConstants.area)
                )
                width = 100.pct
                height = 100.pct
            }

            Header()

            Box {
                component = ReactHTML.main
                sx {
                    gridArea = RootConstants.area
                    padding = 30.px
                    width = 100.pct
                    height = 100.pct
                }

                Outlet()
            }
        }
    }
}