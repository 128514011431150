package com.vermek.core.style

class VMColors {
    companion object {
        // Default Colors
        val white = "#FFFFFF"
        val black = "#000000"

        // Vermek Purple
        val vermekPurple = "#6767E0"
    }
}
