package com.vermek.header.components

import mui.material.Link
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.pct

val Logo = FC<Props> {
    Box {
        sx {
            display = Display.flex
            justifyContent = JustifyContent.left
            width = 100.pct
        }

        Link {
            href = "/"

            ReactHTML.img {
                src = "logo_dark.png"
                height = 50.0
            }
        }
    }
}