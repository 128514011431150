//
// AUTO-GENERATED FILE. DO NOT MODIFY.
//
// This class was automatically generated by Apollo GraphQL version '3.5.0'.
//
package com.vermek

import com.apollographql.apollo3.api.Adapter
import com.apollographql.apollo3.api.CompiledField
import com.apollographql.apollo3.api.CustomScalarAdapters
import com.apollographql.apollo3.api.Mutation
import com.apollographql.apollo3.api.json.JsonWriter
import com.apollographql.apollo3.api.obj
import com.vermek.adapter.SendSMSMessageMutation_ResponseAdapter
import com.vermek.adapter.SendSMSMessageMutation_VariablesAdapter
import com.vermek.selections.SendSMSMessageMutationSelections
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public data class SendSMSMessageMutation(
  public val phoneNumbers: List<String>,
  public val message: String,
) : Mutation<SendSMSMessageMutation.Data> {
  public override fun id(): String = OPERATION_ID

  public override fun document(): String = OPERATION_DOCUMENT

  public override fun name(): String = OPERATION_NAME

  public override fun serializeVariables(writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters): Unit {
    SendSMSMessageMutation_VariablesAdapter.toJson(writer, customScalarAdapters, this)
  }

  public override fun adapter(): Adapter<Data> = SendSMSMessageMutation_ResponseAdapter.Data.obj()

  public override fun rootField(): CompiledField = CompiledField.Builder(
    name = "data",
    type = com.vermek.type.Mutation.type
  )
  .selections(selections = SendSMSMessageMutationSelections.__root)
  .build()

  public data class Data(
    public val sendSMSMessages: List<SendSMSMessage>,
  ) : Mutation.Data

  public data class SendSMSMessage(
    public val description: String?,
    public val phoneNumber: String,
    public val sendStatus: String,
    public val reason: String,
  )

  public companion object {
    public const val OPERATION_ID: String =
        "a070fc4fc1947a83c27385943187b3ad92f4a0c452c8d4d9c0f82096a4fad5ae"

    /**
     * The minimized GraphQL document being sent to the server to save a few bytes.
     * The un-minimized version is:
     *
     * mutation SendSMSMessage($phoneNumbers: \[String!\]!, $message: String!) {
     *   sendSMSMessages(phoneNumbers: $phoneNumbers, message: $message) {
     *     description
     *     phoneNumber
     *     sendStatus
     *     reason
     *   }
     * }
     */
    public val OPERATION_DOCUMENT: String
      get() =
          "mutation SendSMSMessage(${'$'}phoneNumbers: [String!]!, ${'$'}message: String!) { sendSMSMessages(phoneNumbers: ${'$'}phoneNumbers, message: ${'$'}message) { description phoneNumber sendStatus reason } }"

    public const val OPERATION_NAME: String = "SendSMSMessage"
  }
}
