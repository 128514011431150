package com.vermek.core.authentication.models

import com.vermek.core.authentication.firebase.AuthResult

data class AuthenticationResult(
    val user: AuthenticationUser?
) {
    companion object {
        fun build(firebaseAuthResult: AuthResult): AuthenticationResult =
            AuthenticationResult(firebaseAuthResult.user)
    }
}