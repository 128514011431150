package com.vermek.core.authentication.firebase

import com.vermek.core.authentication.AuthenticationHelper
import com.vermek.core.authentication.models.AuthenticationResult
import com.vermek.core.authentication.models.AuthenticationToken
import com.vermek.core.authentication.models.AuthenticationUser

/**
 * Firebase implementation of the authentication helper for this app
 */
object FirebaseAuthHelper : AuthenticationHelper {

    override fun configure() {
        Firebase.initialize(options = getConfiguration())
    }

    override suspend fun getToken(): AuthenticationToken? = getCurrentUser()
        ?.getToken()

    override fun onAuthStateChanged(completion: (AuthenticationUser?) -> Unit): () -> Unit =
        Firebase.auth.onAuthStateChanged { completion(it) }

    override suspend fun getCurrentUser(): AuthenticationUser? = Firebase.auth.currentUser

    override suspend fun signInWithEmailAndPassword(
        email: String,
        password: String
    ): AuthenticationResult =
        AuthenticationResult.build(Firebase.auth.signInWithEmailAndPassword(email, password))

    override suspend fun signOut() {
        Firebase.auth.signOut()
    }
}

/**
 * Provides configuration values depending on the platform for the Firebase app
 */
expect fun FirebaseAuthHelper.getConfiguration(): FirebaseOptions
