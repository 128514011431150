//
// AUTO-GENERATED FILE. DO NOT MODIFY.
//
// This class was automatically generated by Apollo GraphQL version '3.5.0'.
//
package com.vermek

import com.apollographql.apollo3.api.Adapter
import com.apollographql.apollo3.api.CompiledField
import com.apollographql.apollo3.api.CustomScalarAdapters
import com.apollographql.apollo3.api.Query
import com.apollographql.apollo3.api.json.JsonWriter
import com.apollographql.apollo3.api.obj
import com.vermek.adapter.GetCurrentUserQuery_ResponseAdapter
import com.vermek.selections.GetCurrentUserQuerySelections
import kotlin.Any
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Unit

public class GetCurrentUserQuery() : Query<GetCurrentUserQuery.Data> {
  public override fun equals(other: Any?): Boolean = other != null && other::class == this::class

  public override fun hashCode(): Int = this::class.hashCode()

  public override fun id(): String = OPERATION_ID

  public override fun document(): String = OPERATION_DOCUMENT

  public override fun name(): String = OPERATION_NAME

  public override fun serializeVariables(writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters): Unit {
    // This operation doesn't have any variable
  }

  public override fun adapter(): Adapter<Data> = GetCurrentUserQuery_ResponseAdapter.Data.obj()

  public override fun rootField(): CompiledField = CompiledField.Builder(
    name = "data",
    type = com.vermek.type.Query.type
  )
  .selections(selections = GetCurrentUserQuerySelections.__root)
  .build()

  public data class Data(
    public val getCurrentUser: GetCurrentUser?,
  ) : Query.Data

  public data class GetCurrentUser(
    public val id: String,
    public val firstName: String?,
    public val lastName: String?,
  )

  public companion object {
    public const val OPERATION_ID: String =
        "772b386381438546dce5031f01d89264850970fa40e3a73332edf29f92dc1307"

    /**
     * The minimized GraphQL document being sent to the server to save a few bytes.
     * The un-minimized version is:
     *
     * query GetCurrentUser {
     *   getCurrentUser {
     *     id
     *     firstName
     *     lastName
     *   }
     * }
     */
    public val OPERATION_DOCUMENT: String
      get() = "query GetCurrentUser { getCurrentUser { id firstName lastName } }"

    public const val OPERATION_NAME: String = "GetCurrentUser"
  }
}
