package com.vermek.modules.authentication

import com.vermek.core.authentication.AuthenticationHelper
import com.vermek.core.authentication.firebase.FirebaseAuthHelper
import com.vermek.modules.VMModule
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlin.coroutines.CoroutineContext

data class AuthenticationModuleDeps(
    val authenticationHelper: AuthenticationHelper = FirebaseAuthHelper
)

class AuthenticationModule(
    private val dependencies: AuthenticationModuleDeps
): VMModule(), CoroutineScope {
    private val job = Job()
    override val coroutineContext: CoroutineContext
        get() = job + Dispatchers.Default

    /**
     * Attempts to sign in a user given email/password combo
     */
    fun handleSignInUserWithEmailAndPassword(email: String, password: String, completion: (Error?) -> Unit) {
        launch {
            try {
                val result = dependencies.authenticationHelper.signInWithEmailAndPassword(email, password)

                withContext(Dispatchers.Main) {
                    completion(null)
                }
            } catch (e: Exception) {
                withContext(Dispatchers.Main) {
                    completion(Error(e.message))
                }
            }
        }
    }
}