package com.vermek.core.network

import com.apollographql.apollo3.api.http.HttpRequest
import com.apollographql.apollo3.api.http.HttpResponse
import com.apollographql.apollo3.network.http.HttpInterceptor
import com.apollographql.apollo3.network.http.HttpInterceptorChain
import com.vermek.core.authentication.AuthenticationHelper

class AuthenticationInterceptor(
    private val authenticationHelper: AuthenticationHelper
): HttpInterceptor {
    override suspend fun intercept(
        request: HttpRequest,
        chain: HttpInterceptorChain
    ): HttpResponse {
        val token = authenticationHelper.getToken()
        var newRequest = request

        // Add authorization header if token is present
        if (token != null) {
            newRequest = request.newBuilder()
                .addHeader("Authorization", "bearer ${token.bearer}")
                .build()
        }



        return chain.proceed(newRequest)
    }
}
