package com.vermek.modules.application.clients

import com.vermek.GetCurrentUserQuery
import com.vermek.core.network.NetworkResponse
import com.vermek.core.network.VMServiceClient

class UserClient: VMServiceClient() {

    /**
     * Gets the current user logged into the platform
     */
    suspend fun getCurrentUser(): NetworkResponse<GetCurrentUserQuery.GetCurrentUser> {
        val response = client.query(GetCurrentUserQuery()).execute()

        return NetworkResponse(
            response.data?.getCurrentUser,
            response.errors
        )
    }

}