package com.vermek.modules.application

import com.vermek.core.authentication.AuthenticationHelper
import com.vermek.core.authentication.firebase.FirebaseAuthHelper
import com.vermek.core.authentication.models.AuthenticationUser
import com.vermek.modules.VMModule
import com.vermek.modules.application.models.ApplicationStatus
import com.vermek.modules.application.models.User
import com.vermek.modules.application.repositories.UserRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext

class ApplicationModule(
    private val authenticationHelper: AuthenticationHelper = FirebaseAuthHelper,
    val applicationStore: ApplicationStore = ApplicationStore(),
    val userRepository: UserRepository = UserRepository()
): VMModule(), CoroutineScope {

    private val job = Job()

    override val coroutineContext: CoroutineContext
        get() = Dispatchers.Default + job

    // TODO: Should figure out a way to call unsubscribe with this module?
    private var unsubscribe: (() -> Unit)? = null

    init {
        authenticationHelper.configure()
    }

    override fun load() {
        // Listen to changes in the auth state
        unsubscribe = authenticationHelper.onAuthStateChanged {
            launch {
                onAuthStateChanged(it)
            }
        }
    }

    /**
     * Suspend function that handles updating the current state given changes in the auth state
     */
    private suspend fun onAuthStateChanged(authUser: AuthenticationUser?) {
        var user: User? = null
        if (authUser != null) {
            user = userRepository.getCurrentUser()
        }

        applicationStore.updateStatus(ApplicationStatus.LOADED)
        applicationStore.updateCurrentUser(user)
    }

    fun handleLogout(completion: (() -> Unit)? = null) {
        launch {
            authenticationHelper.signOut()

            if (completion != null) {
                completion()
            }
        }
    }
}