package com.vermek

import com.vermek.modules.application.models.ApplicationState
import react.FC
import react.PropsWithChildren
import react.StateInstance
import react.createContext
import react.useEffectOnce
import react.useRequiredContext
import react.useState

val AuthenticationContext = createContext<StateInstance<ApplicationState>>()
val AuthenticationProvider = FC<PropsWithChildren> { props ->
    val dependencies = useRequiredContext(AppDependencyContext)
    val applicationState: StateInstance<ApplicationState> = useState(ApplicationState())

    useEffectOnce {
        dependencies.applicationModule.applicationStore.subscribe {
            applicationState.component2()(it)
        }

        // Kick off the loading process for the application module
        dependencies.applicationModule.load()

        cleanup {
            // Do nothing for now
        }
    }

    AuthenticationContext(applicationState) {
        +props.children
    }
}