package com.vermek.core

/**
 * Server environment that the server can be in. Currently only supporting development and production
 */
enum class ServerEnvironment {
    development, production
}

/**
 * Defines expected interface to access environment variables to use
 * for the application
 */
expect class Environment {
    companion object {

        // Backend server url that this client accesses
        val serverUrl: String

        // Environment that the server can be in
        val serverEnvironment: ServerEnvironment
    }
}