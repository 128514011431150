package com.vermek.sketch

import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.system.sx
import react.FC
import react.ReactNode
import web.cssom.px

val ActionButton = FC<ActionButtonProps> { props ->
    Button {
        sx {
            paddingRight = 0.px
            height = 50.px
            +props.sx
        }
        disabled = props.loading
        onClick = props.onClick
        fullWidth = true
        variant = ButtonVariant.contained

        if (props.loading) {
            CircularProgress {
                size = 30
            }
        } else {
            +props.children
        }
    }
}

external interface ActionButtonProps:
    react.dom.html.ButtonHTMLAttributes<web.html.HTMLButtonElement>,
    react.PropsWithChildren,
    mui.system.PropsWithSx
{
    override var children: ReactNode?
    var loading: Boolean
}