package com.vermek.header.components

import mui.material.Button
import react.FC
import react.Props
import react.router.useNavigate

val HeaderUnauthenticated = FC<Props> {
    val navigate = useNavigate()

    Logo()

    Button {
        onClick = { navigate("/login") }
        +"Login"
    }
}