package com.vermek

import react.Component
import react.ComponentType
import react.FC
import react.Props
import react.router.useNavigate
import react.useRequiredContext

/**
 * A private route is a route that can be protected by ensuring a user is logged in.
 * There are two different types of private routes:
 *      1. Routes where when the user is not logged in we want to auto-redirect them to the login page
 *      2. Routes where when the user is logged out there is another default unauthenticated component that we show to them
 *
 * If the route is provided with an unauthenticated component type then we'll show that by default
 * If there is no unauthenticated component provided then this private route will automatically
 * redirect to the login page
 */
val PrivateRoute = FC<PrivateRouteProps> {
    val authContext by useRequiredContext(AuthenticationContext)
    val navigate = useNavigate()
    val redirectToLogin: Boolean = it.unauthenticatedComponentType == null

    if (authContext.isAuthenticated()) {
        it.authenticatedComponentType()
    } else if (authContext.isLoading()) {
        it.skeletonComponentType()
    } else if (!redirectToLogin) { // Someone provided an unauthenticated component, so show it
        it.unauthenticatedComponentType?.let {
            it()
        }
    } else { // all other cases default to redirecting to login page
        navigate("/login")
    }
}

external interface PrivateRouteProps: Props {
    var unauthenticatedComponentType: ComponentType<*>?
    var authenticatedComponentType: ComponentType<*>
    var skeletonComponentType: ComponentType<*>
}