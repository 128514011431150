package com.vermek.header.components

import com.vermek.AppDependencyContext
import mui.material.Button
import mui.material.ButtonVariant
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useRequiredContext
import web.cssom.px

val HeaderAuthenticated = FC<Props> {
    val dependencies = useRequiredContext(AppDependencyContext)
    val navigate = useNavigate()

    fun handleLogout() {
        dependencies.applicationModule.handleLogout {
            navigate("/login")
        }
    }

    Logo()

    Button {
        onClick = { navigate("/message") }
        +"Message"
    }

    Button {
        sx {
            marginLeft = 10.px
        }
        variant = ButtonVariant.text
        onClick = { handleLogout() }

        +"Logout"
    }
}