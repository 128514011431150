package com.vermek.core.network

import com.apollographql.apollo3.ApolloClient
import com.vermek.core.Environment
import com.vermek.core.authentication.AuthenticationHelper
import com.vermek.core.authentication.firebase.FirebaseAuthHelper

open class VMServiceClient(
    authenticationHelper: AuthenticationHelper = FirebaseAuthHelper
) {
    val client: ApolloClient = ApolloClient.Builder()
        .addHttpInterceptor(AuthenticationInterceptor(authenticationHelper))
        .serverUrl(Environment.serverUrl)
        .build()
}