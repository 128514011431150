package com.vermek.modules

import com.badoo.reaktive.disposable.scope.DisposableScope
import com.badoo.reaktive.observable.subscribe
import com.badoo.reaktive.subject.behavior.BehaviorSubject

open class VMMutableStore<T: VMState>(initialState: T): DisposableScope by DisposableScope() {
    private val behaviorSubject = BehaviorSubject(initialState).scope { it.onComplete() }

    var state: T
        get() = behaviorSubject.value
        set(value) {
            if (behaviorSubject.value != value) {
                behaviorSubject.onNext(value)
            }
        }

    /**
     * Subscribes an observer to the changes in this store's behavior subject.
     * Provides notifications of state updates to the observer
     */
    fun subscribe(onNext: (T) -> Unit) {
        behaviorSubject.subscribe(onNext = onNext)
    }
}