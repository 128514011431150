package com.vermek.modules.application.models

import com.vermek.modules.VMState

enum class ApplicationStatus {
    LOADING, LOADED
}

data class ApplicationState(
    var applicationStatus: ApplicationStatus = ApplicationStatus.LOADING,
    var currentUser: User? = null
): VMState {
    fun isLoading(): Boolean = applicationStatus == ApplicationStatus.LOADING

    fun isUnauthenticated(): Boolean =
        applicationStatus == ApplicationStatus.LOADED && currentUser == null

    fun isAuthenticated(): Boolean =
        applicationStatus == ApplicationStatus.LOADED && currentUser != null
}
