package com.vermek.modules.application

import com.vermek.modules.VMMutableStore
import com.vermek.modules.application.models.ApplicationState
import com.vermek.modules.application.models.ApplicationStatus
import com.vermek.modules.application.models.User

class ApplicationStore: VMMutableStore<ApplicationState>(
    initialState = ApplicationState()
) {

    fun updateCurrentUser(currentUser: User?) {
        state = state.copy(currentUser = currentUser)
    }

    fun updateStatus(applicationStatus: ApplicationStatus) {
        state = state.copy(applicationStatus = applicationStatus)
    }
}
