package com.vermek.message

import com.vermek.ThemeContext
import com.vermek.modules.sendMessage.SendMessageModule
import com.vermek.sketch.ActionButton
import mui.material.Alert
import mui.material.AlertColor
import mui.material.TextField
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Box
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.onChange
import react.useRequiredContext
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import web.html.InputType
import kotlin.math.ceil

val MessageAuthenticated = FC<Props> {
    val theme by useRequiredContext(ThemeContext)
    val sendMessageModule = SendMessageModule()
    var phoneNumbers: List<String> by useState(emptyList())
    var message by useState("")
    var feedbackType by useState<AlertColor?>(null)
    var feedbackMessage by useState("")
    var isLoading by useState(false)
    var cost by useState("0.00")

    // TODO: put cost calculations below on the backend and make this an API call. Helps to improve accuracy. For now it's probably fine to estimate
    val carrierCostPerSegment = 30 // 30 hundreths of a cent
    val twilioCostPerSegment = 79 // 79 hundreths of a cent. All calculations are based on hundreths of cents
    val numCharsPerSegment = 152.0 // Number of UTF8 characters the can be present in a message

    fun calculateCost(numPhoneNumbers: Int, numCharsInMessage: Int): String {
        val numSegments = ceil(numCharsInMessage / numCharsPerSegment)
        val messageCost = numSegments * (carrierCostPerSegment + twilioCostPerSegment)
        val costInHundrethsCents = numPhoneNumbers * messageCost
        val numDollars = costInHundrethsCents / 10000.0

        console.log("Cost: " + numDollars)
        return numDollars.asDynamic().toFixed(3) as String
    }

    /**
     * When values are inputted to the phone number field we can parse the values to get
     * the phone numbers needed to send messages. Parsing the phone numbers into a list allows
     * for presenting some important info to the user sending the message. E.g. cost
     */
    fun handlePhoneNumberChanged(value: String) {
        val numbers = value.split(",") // Split input on comma to get phone numbers
        phoneNumbers = numbers

        // Calculate cost when changing phone number field
        cost = calculateCost(numbers.size, message.count())
    }

    /**
     * Handles situation when message is changed. Make sure to calculate cost
     */
    fun handleMessageChanged(value: String) {
        message = value
        cost = calculateCost(phoneNumbers.size, value.count())
    }

    fun handleSendMessageClicked() {
        var hasError = false
        var errorDescription = ""
        // Check for valid input
        if (phoneNumbers.isEmpty()) {
            hasError = true
            errorDescription = "Please input at least one phone number"
        } else if (message.isEmpty()) {
            hasError = true
            errorDescription = "Please input a message to send"
        }

        if (hasError) { // break out of the function if we cannot validate input
            feedbackType = AlertColor.error
            feedbackMessage = errorDescription
            return
        } else {
            feedbackType = null
            feedbackMessage = ""
        }

        isLoading = true
        sendMessageModule.sendMessage(phoneNumbers, message) { statuses, errors ->
            isLoading = false

            if (statuses != null) {
                feedbackType = AlertColor.success
                feedbackMessage = "Successfully queued messages to ${statuses.size} phone numbers. Check Twilio for status of the messages."
            } else if (!errors.isNullOrEmpty()) {
                feedbackType = AlertColor.error
                feedbackMessage = "Oops, there was an issue sending messages: ${errors[0].message}"
            }
        }
    }

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            width = 100.pct
            height = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                maxWidth = 600.px
                backgroundColor = theme.palette.secondary.main
                padding = 20.px
                borderRadius = 10.px
            }

            Typography {
                sx {
                    paddingBottom = 20.px
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.h4

                +"Send a message"
            }

            Typography {
                sx {
                    paddingBottom = 20.px
                }

                +"Use this tool to send an SMS message to a list of phone numbers."
            }

            TextField {
                label = ReactNode("Phone Numbers (comma-separated)")
                fullWidth = true
                onChange = { event -> handlePhoneNumberChanged(event.target.asDynamic().value as String) }
            }

            TextField {
                sx {
                    marginTop = 10.px
                }
                label = ReactNode("SMS Message")
                type = InputType.password
                fullWidth = true
                multiline = true
                onChange = { event -> handleMessageChanged(event.target.asDynamic().value as String) }
            }

            Typography {
                sx {
                    marginTop = 10.px
                }
                +"Estimated Cost: $${cost}"
            }

            ActionButton {
                sx {
                    marginTop = 10.px
                }
                loading = isLoading
                onClick = { handleSendMessageClicked() }
                +"Send Message"
            }

            // Show feedback message on success or failure
            if (feedbackType == AlertColor.error || feedbackType == AlertColor.success) {
                Alert {
                    sx {
                        width = 100.pct
                        marginTop = 10.px
                    }
                    severity = feedbackType

                    +feedbackMessage
                }
            }
        }
    }
}
