//
// AUTO-GENERATED FILE. DO NOT MODIFY.
//
// This class was automatically generated by Apollo GraphQL version '3.5.0'.
//
package com.vermek.adapter

import com.apollographql.apollo3.api.Adapter
import com.apollographql.apollo3.api.CustomScalarAdapters
import com.apollographql.apollo3.api.NullableStringAdapter
import com.apollographql.apollo3.api.StringAdapter
import com.apollographql.apollo3.api.json.JsonReader
import com.apollographql.apollo3.api.json.JsonWriter
import com.apollographql.apollo3.api.nullable
import com.apollographql.apollo3.api.obj
import com.vermek.GetCurrentUserQuery
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public object GetCurrentUserQuery_ResponseAdapter {
  public object Data : Adapter<GetCurrentUserQuery.Data> {
    public val RESPONSE_NAMES: List<String> = listOf("getCurrentUser")

    public override fun fromJson(reader: JsonReader, customScalarAdapters: CustomScalarAdapters):
        GetCurrentUserQuery.Data {
      var _getCurrentUser: GetCurrentUserQuery.GetCurrentUser? = null

      while(true) {
        when (reader.selectName(RESPONSE_NAMES)) {
          0 -> _getCurrentUser = GetCurrentUser.obj().nullable().fromJson(reader,
              customScalarAdapters)
          else -> break
        }
      }

      return GetCurrentUserQuery.Data(
        getCurrentUser = _getCurrentUser
      )
    }

    public override fun toJson(
      writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters,
      `value`: GetCurrentUserQuery.Data,
    ): Unit {
      writer.name("getCurrentUser")
      GetCurrentUser.obj().nullable().toJson(writer, customScalarAdapters, value.getCurrentUser)
    }
  }

  public object GetCurrentUser : Adapter<GetCurrentUserQuery.GetCurrentUser> {
    public val RESPONSE_NAMES: List<String> = listOf("id", "firstName", "lastName")

    public override fun fromJson(reader: JsonReader, customScalarAdapters: CustomScalarAdapters):
        GetCurrentUserQuery.GetCurrentUser {
      var _id: String? = null
      var _firstName: String? = null
      var _lastName: String? = null

      while(true) {
        when (reader.selectName(RESPONSE_NAMES)) {
          0 -> _id = StringAdapter.fromJson(reader, customScalarAdapters)
          1 -> _firstName = NullableStringAdapter.fromJson(reader, customScalarAdapters)
          2 -> _lastName = NullableStringAdapter.fromJson(reader, customScalarAdapters)
          else -> break
        }
      }

      return GetCurrentUserQuery.GetCurrentUser(
        id = _id!!,
        firstName = _firstName,
        lastName = _lastName
      )
    }

    public override fun toJson(
      writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters,
      `value`: GetCurrentUserQuery.GetCurrentUser,
    ): Unit {
      writer.name("id")
      StringAdapter.toJson(writer, customScalarAdapters, value.id)

      writer.name("firstName")
      NullableStringAdapter.toJson(writer, customScalarAdapters, value.firstName)

      writer.name("lastName")
      NullableStringAdapter.toJson(writer, customScalarAdapters, value.lastName)
    }
  }
}
