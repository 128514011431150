//
// AUTO-GENERATED FILE. DO NOT MODIFY.
//
// This class was automatically generated by Apollo GraphQL version '3.5.0'.
//
package com.vermek.adapter

import com.apollographql.apollo3.api.Adapter
import com.apollographql.apollo3.api.CustomScalarAdapters
import com.apollographql.apollo3.api.NullableStringAdapter
import com.apollographql.apollo3.api.StringAdapter
import com.apollographql.apollo3.api.json.JsonReader
import com.apollographql.apollo3.api.json.JsonWriter
import com.apollographql.apollo3.api.list
import com.apollographql.apollo3.api.obj
import com.vermek.SendSMSMessageMutation
import kotlin.String
import kotlin.Unit
import kotlin.collections.List

public object SendSMSMessageMutation_ResponseAdapter {
  public object Data : Adapter<SendSMSMessageMutation.Data> {
    public val RESPONSE_NAMES: List<String> = listOf("sendSMSMessages")

    public override fun fromJson(reader: JsonReader, customScalarAdapters: CustomScalarAdapters):
        SendSMSMessageMutation.Data {
      var _sendSMSMessages: List<SendSMSMessageMutation.SendSMSMessage>? = null

      while(true) {
        when (reader.selectName(RESPONSE_NAMES)) {
          0 -> _sendSMSMessages = SendSMSMessage.obj().list().fromJson(reader, customScalarAdapters)
          else -> break
        }
      }

      return SendSMSMessageMutation.Data(
        sendSMSMessages = _sendSMSMessages!!
      )
    }

    public override fun toJson(
      writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters,
      `value`: SendSMSMessageMutation.Data,
    ): Unit {
      writer.name("sendSMSMessages")
      SendSMSMessage.obj().list().toJson(writer, customScalarAdapters, value.sendSMSMessages)
    }
  }

  public object SendSMSMessage : Adapter<SendSMSMessageMutation.SendSMSMessage> {
    public val RESPONSE_NAMES: List<String> = listOf("description", "phoneNumber", "sendStatus",
        "reason")

    public override fun fromJson(reader: JsonReader, customScalarAdapters: CustomScalarAdapters):
        SendSMSMessageMutation.SendSMSMessage {
      var _description: String? = null
      var _phoneNumber: String? = null
      var _sendStatus: String? = null
      var _reason: String? = null

      while(true) {
        when (reader.selectName(RESPONSE_NAMES)) {
          0 -> _description = NullableStringAdapter.fromJson(reader, customScalarAdapters)
          1 -> _phoneNumber = StringAdapter.fromJson(reader, customScalarAdapters)
          2 -> _sendStatus = StringAdapter.fromJson(reader, customScalarAdapters)
          3 -> _reason = StringAdapter.fromJson(reader, customScalarAdapters)
          else -> break
        }
      }

      return SendSMSMessageMutation.SendSMSMessage(
        description = _description,
        phoneNumber = _phoneNumber!!,
        sendStatus = _sendStatus!!,
        reason = _reason!!
      )
    }

    public override fun toJson(
      writer: JsonWriter,
      customScalarAdapters: CustomScalarAdapters,
      `value`: SendSMSMessageMutation.SendSMSMessage,
    ): Unit {
      writer.name("description")
      NullableStringAdapter.toJson(writer, customScalarAdapters, value.description)

      writer.name("phoneNumber")
      StringAdapter.toJson(writer, customScalarAdapters, value.phoneNumber)

      writer.name("sendStatus")
      StringAdapter.toJson(writer, customScalarAdapters, value.sendStatus)

      writer.name("reason")
      StringAdapter.toJson(writer, customScalarAdapters, value.reason)
    }
  }
}
