package com.vermek.modules

/**
 * Declares Module used in app to categorize code and
 * states for different parts of the app
 */
open class VMModule {

    open fun load() {
        // NO-OP
    }
}