package com.vermek

import com.vermek.home.HomeAuthenticated
import com.vermek.home.HomeSkeleton
import com.vermek.home.HomeUnauthenticated
import com.vermek.login.LoginComponent
import com.vermek.message.MessageAuthenticated
import com.vermek.message.MessageSkeleton
import com.vermek.root.RootComponent
import js.core.jso
import react.FC
import react.Props
import react.create
import react.dom.client.createRoot
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import web.dom.document
import web.html.HTML.div

fun main() {
    val root = document.createElement(div)
        .also { document.body.appendChild(it) }

    createRoot(root).render(App.create())
}

private val hashRouter = createBrowserRouter(
    routes = arrayOf(

        jso {
            path = "/"
            element = RootComponent.create()
            children = arrayOf(
                jso {
                    index = true
                    element = PrivateRoute.create {
                        unauthenticatedComponentType = HomeUnauthenticated
                        authenticatedComponentType = HomeAuthenticated
                        skeletonComponentType = HomeSkeleton
                    }
                },
                jso {
                    path = "login"
                    element = LoginComponent.create()
                },
                jso {
                    path = "message"
                    element = PrivateRoute.create {
                        authenticatedComponentType = MessageAuthenticated
                        skeletonComponentType = MessageSkeleton
                    }
                }
            )
        }
    ),
)

private val App = FC<Props> {
    ThemeModule {
        AppDependencyProvider {
            AuthenticationProvider {
                RouterProvider {
                    router = hashRouter
                }
            }
        }
    }
}
