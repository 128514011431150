package com.vermek.home

import com.vermek.AuthenticationContext
import com.vermek.ThemeContext
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useRequiredContext
import web.cssom.px

val HomeAuthenticated = FC<Props> {
    val theme by useRequiredContext(ThemeContext)
    val authContext by useRequiredContext(AuthenticationContext)
    val userFirstName = if (authContext.currentUser?.firstName?.isNotEmpty() == true) " ${authContext.currentUser?.firstName}" else ""

    Typography {
        sx {
            color = theme.palette.common.white
            marginTop = 20.px
        }
        variant = TypographyVariant.h6
        +"Welcome${userFirstName}! Use the menu items above to send a message or log out."
    }
}
