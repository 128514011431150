package com.vermek.modules.sendMessage

import com.apollographql.apollo3.api.Error
import com.vermek.SendSMSMessageMutation
import com.vermek.modules.VMModule
import com.vermek.modules.sendMessage.repositories.MessageRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext

class SendMessageModule(
    private val messageRepository: MessageRepository = MessageRepository()
): VMModule(), CoroutineScope {

    private val job = Job()

    override val coroutineContext: CoroutineContext
        get() = Dispatchers.Default + job

    fun sendMessage(phoneNumbers: List<String>, message: String, completion: SendMessageCompletion) {
        launch {
            val response = messageRepository.sendMessage(phoneNumbers, message)
            completion(response.data, response.errors)
        }
    }
}

typealias SendMessageCompletion = (List<SendSMSMessageMutation.SendSMSMessage>?, List<Error>?) -> Unit