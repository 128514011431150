package com.vermek.core

/**
 * Defines the external process object in JS
 */
external object process {
    val env: ProcessEnv
}

/**
 * Definition of a static interface to access environment variables
 */
external interface ProcessEnv {
    val SERVER_URL: String
    val ENVIRONMENT: String
}

actual class Environment {
    actual companion object {

        actual val serverUrl: String = process.env.SERVER_URL
        actual val serverEnvironment: ServerEnvironment = ServerEnvironment.valueOf(process.env.ENVIRONMENT)
    }
}