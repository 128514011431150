package com.vermek.modules.sendMessage.clients

import com.vermek.SendSMSMessageMutation
import com.vermek.core.network.NetworkResponse
import com.vermek.core.network.VMServiceClient

class MessageClient: VMServiceClient() {
    suspend fun sendMessage(
        phoneNumbers: List<String>,
        message: String
    ): NetworkResponse<List<SendSMSMessageMutation.SendSMSMessage>> {
        val response =
            client.mutation(SendSMSMessageMutation(phoneNumbers, message))
                .execute()

        return NetworkResponse(
            response.data?.sendSMSMessages,
            response.errors
        )
    }
}