package com.vermek.modules.application.models

import com.vermek.GetCurrentUserQuery
import com.vermek.core.authentication.firebase.FirebaseUser

/**
 * Stores relevant data about the currently logged in user
 */
data class User(
    val firstName: String?,
    val lastName: String?,
    val phoneNumber: String?,
    val emailAddress: String?
) {
    companion object {
        fun build(
            userQueryData: GetCurrentUserQuery.GetCurrentUser
        ): User = User(
            userQueryData.firstName,
            userQueryData.lastName,
            null,
            null
        )
    }
}
