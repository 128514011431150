package com.vermek.core.authentication.firebase

import com.vermek.core.Environment
import com.vermek.core.ServerEnvironment

actual fun FirebaseAuthHelper.getConfiguration(): FirebaseOptions {
    return when (Environment.serverEnvironment) {
        ServerEnvironment.development -> FirebaseOptions(
            applicationId = "1:275154692489:web:0647036f042b3a2b27d958",
            apiKey = "AIzaSyDo5R5bneXV7R4-P_ARYr-kF0KTUuHdFig",
            authDomain = "vermek-dev.firebaseapp.com",
            projectId = "vermek-dev",
            gcmSenderId = "275154692489",
            storageBucket = "vermek-dev.appspot.com",
            gaTrackingId = "G-WT2KL7ZVT5"
        )
        ServerEnvironment.production -> FirebaseOptions(
            applicationId = "1:619894482493:web:253796c67261f202f22c54",
            apiKey = "AIzaSyCX3MJD4ZMg1j2iMGJZyKkTIoHr5yZ_gtk",
            authDomain = "vermek-8a9db.firebaseapp.com",
            projectId = "vermek-8a9db",
            gcmSenderId = "619894482493",
            storageBucket = "vermek-8a9db.appspot.com",
            gaTrackingId = "G-RXPRDDGFVF"
        )
    }
}
