package com.vermek.modules.application.repositories

import com.vermek.modules.application.models.User
import com.vermek.modules.application.clients.UserClient

class UserRepository(
    private val userClient: UserClient = UserClient()
) {
    private var user: User? = null

    suspend fun getCurrentUser(): User? {
        // Get from in memory cache if the user exists
        if (user != null) {
            return user
        }

        // Get user from network
        val response = userClient.getCurrentUser()
        if (response.data != null) {
            user = User.build(response.data)
            return user
        }

        // Return null if we failed to get user from network
        // TODO maybe throw error if we can't get user?
        return null
    }
}